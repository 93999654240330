
import { Component, Vue } from 'vue-property-decorator';

import { Store } from 'vuex';
import { IVodozaporkaFilter, IVodozaporka } from '@/interfaces';
import { readVodozaporkaFilter, readVodozaporka, readRowsPerPage } from '@/store/main/getters';
import { dispatchUpdateVodozaporkaFilter, dispatchUpdateRowsPerPage } from '@/store/main/actions';

@Component
export default class SearchVodozaporka extends Vue {
    public vodozaporka_search: string = '';
    public pagination = {}
    public selectedRows = [];
    public expand = true;
    
    get vendors() {
        var vz = readVodozaporka(this.$store)
        var v = []
        for (var i = 0; i < vz.length; i++){
            if (vz[i]['prices']) {
                v = v.concat(Object.keys(vz[i]['prices']))
            }
        }
        v = Array.from(new Set(v));
        return v;
    }
    
    get headers() {
        var vz = readVodozaporka(this.$store)
        var vendors_titles = []
        for (var i = 0; i < vz.length; i++){
            if (vz[i]['prices']) {
                vendors_titles = vendors_titles.concat(Object.keys(vz[i]['prices']))
            }
        }
        vendors_titles = Array.from(new Set(vendors_titles));

        var h = [
            {text: 'Название файла', value: 'filename', class: 'sticky', sortable: true, align: 'left'},
            {text: 'Наименование товара', value: 'title', class: 'sticky', sortable: true, align: 'left'},
            {text: 'Марка', value: 'marka', class: 'sticky', sortable: true, align: 'left'},
            {text: 'Размер', value: 'razmer', class: 'sticky', sortable: true, align: 'left'},
            {text: 'Код', value: 'code', sortable: true, align: 'left'},
        ];
        
        for (var i = 0; i < vendors_titles.length; i++){
            h.push({text: vendors_titles[i], class: 'wraped', sortable: false, align: 'left'})
        }
        return h;
    }


    get headers_xlsx() {
        var vz = readVodozaporka(this.$store)
        var vendors_titles = []

        for (var i = 0; i < vz.length; i++){
            if (vz[i]['prices']) {
                vendors_titles = vendors_titles.concat(Object.keys(vz[i]['prices']))
            }
        }

        vendors_titles = Array.from(new Set(vendors_titles));

        var h = [
            {label: 'Название файла', field: 'fname'},
            {label: 'Наименование товара', field: 'title'},
            {label: 'Марка', field: 'marka'},
            {label: 'Размер', field: 'razmer'},
            {label: 'Код', field: 'code'},
        ];
        
        for (var i = 0; i < vendors_titles.length; i++){
            h.push({label: vendors_titles[i], field: vendors_titles[i]})
        }
        return h;
    }

    
    get vodozaporka() {                                                         // Получаем из хранилища список товаров
        var vz = readVodozaporka(this.$store)
        for (var i=0; i < vz.length; i++){
            var file_path = vz[i]['filename'].split('/')
            vz[i]['fname'] = file_path[file_path.length - 1]
            vz[i]['link'] = vz[i]['filename'].replace('disk:/', 'https://disk.yandex.ru/client/disk/')
            
            if (vz[i]['prices']) {
                for (const [k, v] of Object.entries(vz[i]['prices'])) {
                    vz[i][k] = parseFloat(v).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                }
            }
        }
        return vz;
    }
    
    get vodozaporka_count() {                                                   // Возвращаем количество найденных товаров
        return this.$store.getters.vodozaporka.length
    }
    
    public async mounted() {
        await this.search_vodozaporka()
    }
    
    public created() {                                                          // На создание страницы
        const vodozaporkaFilter = readVodozaporkaFilter(this.$store);           // Получаем из хранилища goodFilter

        if (vodozaporkaFilter) {                                                // и заполняем им форму
            this.vodozaporka_search = vodozaporkaFilter.vodozaporka_search;
        }
       
        const rowsPerPage = readRowsPerPage(this.$store);                       // Получаем из хранилища сохраненное количество товаров на странице                    
        this.pagination.rowsPerPage = (rowsPerPage) ? rowsPerPage : 10          // Если меняли кол-во товаров, то ставим сохраненное, иначе - 10
    }

    public async updatePagination (pagination) {                                // На изменение кол-ва товаров на странице:
        await dispatchUpdateRowsPerPage(this.$store, pagination.rowsPerPage);   // записываем в хранилище кол-во товаров на странице            
    }

    public async search_vodozaporka() {                                         // на кнопку "Искать"
        const vodozaporkaFilter: IVodozaporkaFilter = {};                       // получаем шаблон фильтра
        vodozaporkaFilter.vodozaporka_search = this.vodozaporka_search;         // запихиваем в шаблон данные из формы

        await dispatchUpdateVodozaporkaFilter(this.$store, vodozaporkaFilter);  // Сохраняем в хранилище данные из формы
    }
}
